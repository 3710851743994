<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col xm="6" sm="6" lg="5" xl="5" class="register-bg-cc py-5">
        <b-img fluid :src="logoBig" alt="Login Side Image" class="img-max-width" />
        <p class="text-white mt-5 px-5 text-center side-bg-text">A solução de crédito  que você precisa para  realizar pagamentos no Sistema Pamcard.</p>
        <b-img fluid :src="auxLogo" alt="Login Side Image" class="img-max-width" />
        <div class="d-flex flex-column justify-content-center align-items-end pt-5">
          <b-img fluid :src="logoFooter" alt="Login Side Image" class="img-max-width" />
        </div>
      </b-col>

      <b-col xm="6" sm="6" lg="7" xl="7" class="d-flex align-items-center auth-bg px-2 py-5 p-lg-5">
        <b-col sm="12" md="12" lg="12" xl="10" class="px-xl-2 mx-auto">

          <b-card-title class="mb-1 font-weight-bold d-flex flex-column" title-tag="h2">
            <span class="text-dark">Crie sua conta</span>
          </b-card-title>
          <b-card-text class="mb-2">
            Informe abaixo seus dados cadastrais.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="RegisterForm">
            <b-form class="auth-register-form mt-2">
              <div v-if="step === 1">
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Nome" label-for="register-name">
                      <validation-provider #default="{ errors }" name="Nome" vid="name" rules="required">
                        <b-form-input id="register-name" v-model="userName" :state="errors.length > 0 ? false : null"
                          name="register-name" placeholder="Informe seu nome completo" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Email" label-for="register-email">
                      <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
                        <b-form-input id="register-email" v-model="userEmail" type="email"
                          :state="errors.length > 0 ? false : null" name="register-email" placeholder="Digite seu email"
                          disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Nome fantasia" label-for="register-nome-fantasia">
                      <validation-provider #default="{ errors }" name="Nome fantasia" vid="nome_fantasia"
                        rules="required">
                        <b-form-input id="register-nome-fantasia" v-model="nome_fantasia"
                          :state="errors.length > 0 ? false : null" name="register-nome-fantasia" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Razão social" label-for="register-razao-social">
                      <validation-provider #default="{ errors }" name="Razão social" vid="razao_social" rules="required">
                        <b-form-input id="register-razao-social" v-model="razao_social"
                          :state="errors.length > 0 ? false : null" name="register-razao-social" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="CNPJ" label-for="register-cnpj">
                      <validation-provider #default="{ errors }" name="CNPJ" vid="cnpj" rules="required">
                        <b-form-input id="register-cnpj" v-model="cnpj" :state="errors.length > 0 ? false : null"
                          name="register-cnpj" disabled />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-button variant="primary" @click="validateUserData" class="continue-button"
                      :disabled="!terms">Continuar <feather-icon icon="ChevronRightIcon" class="ml-1" /> </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group class="mt-1">
                      <b-form-checkbox v-model="terms" name="checkbox-terms" id="checkbox-terms">
                        <div>Eu concordo com os <a
                            href="https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/pamcash/Termos+de+uso+da+plataforma+Pamcash.pdf"
                            target="_blank">Termos de uso</a> e <a
                            href="https://policies-creditcorp-public.s3.sa-east-1.amazonaws.com/pamcash/Pol%C3%ADtica+de+privacidade+da+plataforma+Pamcash.pdf"
                            target="_blank">Política
                            de privacidade</a> da CreditCorp.</div>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div v-if="step === 2">
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="register-password">Senha</label>
                      </div>
                      <validation-provider #default="{ errors }" name="Senha" vid="password" rules="required">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input id="register-password" v-model="password"
                            :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType"
                            name="register-password" placeholder="Password" />
                          <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility('passwordFieldType')" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="register-password">Confirmação de senha</label>
                      </div>
                      <validation-provider #default="{ errors }" name="Confirmação de senha" vid="confirm_password"
                        rules="required|confirmed:password">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input id="register-password-confirmation" v-model="passwordConfirmation"
                            :state="errors.length > 0 ? false : null" class="form-control-merge"
                            :type="passwordConfirmationFieldType" name="register-password-confirmation"
                            placeholder="Password" />
                          <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordConfirmToggleIcon"
                              @click="togglePasswordVisibility('passwordConfirmationFieldType')" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <span> Sua senha precisa conter: </span>
                    <b-form-group class="mt-1">
                      <b-form-checkbox v-model="minimunCaracters" name="checkbox-1" disabled> No mínimo 8 caracteres
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox v-model="capitalLetters" name="checkbox-1" disabled> Letras maiúsculas
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox v-model="smallLetters" name="checkbox-1" disabled> Letras minúsculas
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox v-model="numbers" name="checkbox-1" disabled> Números </b-form-checkbox>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox v-model="symbols" name="checkbox-1" disabled> Simbolos </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" class="d-flex justify-content-between">
                    <b-button variant="outline-primary" @click="step = 1"><feather-icon icon="ChevronLeftIcon"
                        class="mr-1" />Voltar</b-button>
                    <b-button variant="primary" @click="register" :disabled="!strongPassword || !passwordsMatch">Criar conta</b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required, email, cnpj } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      terms: false,
      step: 1,
      logoBig: require('@/assets/images/logo/pamcash-logo.svg'),
      auxLogo: require('@/assets/images/logo/pamcash-icon.svg'),
      logoFooter: require('@/assets/images/logo/logo-developedby.svg'),
      status: '',
      cnpj: '',
      password: '',
      passwordConfirmation: '',
      userName: '',
      phone: '',
      companyName: '',
      userEmail: '',
      required,
      email,
      cnpj,
      razao_social: '',
      nome_fantasia: '',
    }
  },
  async mounted() {
    if (!this.$route.query.token) return

    try {
      const { data } = await this.$store.dispatch('common/validateInvite', this.$route.query.token)
      this.userName = data.nome_contato
      this.userEmail = data.email_contato
      this.cnpj = data.backoffice_empresa.cnpj
      this.razao_social = data.backoffice_empresa.razao_social
      this.nome_fantasia = data.backoffice_empresa.nome_fantasia
    } catch (error) {
      this.$swal({
        title: 'Erro',
        text: error.response.data,
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    minimunCaracters() {
      return this.password.length >= 8
    },
    smallLetters() {
      return /[a-z]/.test(this.password)
    },
    capitalLetters() {
      return /[A-Z]/.test(this.password)
    },
    numbers() {
      return /[0-9]/.test(this.password)
    },
    symbols() {
      return /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    },
    strongPassword() {
      return this.minimunCaracters && this.smallLetters && this.capitalLetters && this.numbers && this.symbols
    },
    termsOfUse() {
      return process.env.VUE_APP_TERMS_OF_USE
    },
    privacyPolicy() {
      return process.env.VUE_APP_PRIVACY_POLICY
    },
    passwordsMatch() {
      return this.password === this.passwordConfirmation
    }
  },
  methods: {
    async validateUserData() {
      const success = await this.$refs.RegisterForm.validate()
      if (success) this.step = 2
    },
    async register() {
      try {
        const payload = {
          email: this.userEmail,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          privacy_policy_term_link: 'https://linktermo1.com',
          use_policy_term_link: 'https://linktermo2.com',
          token: this.$route.query.token,
        }

        this.$swal.showLoading()

        await this.$store.dispatch('auth/createUser', payload)

        this.$swal({
          title: 'Sucesso!',
          text: 'Usuário cadastrado com sucesso!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

        this.$router.push('auth-login')
      } catch (error) {
        this.$refs.RegisterForm.setErrors(error.response)
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.register-bg-cc {
  background: linear-gradient(180deg, #002B5E 0%, #0070AF 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.side-bg-text {
  padding-left: 3rem;
  padding-right: 3rem;
}

.continue-button {
  margin-top: 1.5rem;
}

.img-max-width {
  max-width: 250px
}

@media (min-width: 1679px) {
  .img-max-width {
    max-width: 450px !important;
  }

  .side-bg-text {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

// #checkbox-terms  + .custom-control-label::before,
// #checkbox-terms + .custom-control-label::after {
//   top: 1.6rem;
// }
</style>
